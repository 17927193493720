<template>
    <div
        class="scroll-top-container"
        :class="{ showing: isShown, scrolling: isScrolling }"
    >
        <div
            class="scroll-top-icon"
            :class="[
                `bg-${config.toTopButton.bgColor}`,
                `text-${config.toTopButton.iconColor}`,
                `hover-text-color-${config.toTopButton.bgColor}`,
                isScrolling ? `text-color-${config.toTopButton.bgColor}` : '',
            ]"
            @click="toTop()"
            :style="{
                borderRadius: config.toTopButton.roundedCorners ? '8px' : '0',
            }"
        >
            <i :class="[config.toTopButton.iconClass]"></i>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import { CONFIG_OPTIONS } from "@src/globals";

const config = CONFIG_OPTIONS;
let isShown = ref(false);
let isScrolling = ref(false);

onMounted(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
});

onUnmounted(() => {
    window.removeEventListener("scroll", onScroll);
});

function onScroll(e: any) {
    if (typeof window === "undefined") return;
    const top = window.pageYOffset || e.target.scrollTop || 0;

    isShown.value = top > window.innerHeight - 200;
}

function toTop() {
    let scrollToTop = window.setInterval(() => {
        isScrolling.value = true;
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 100); // how far to scroll on each step
        } else {
            isScrolling.value = false;
            window.clearInterval(scrollToTop);
        }
    }, 16);
}
</script>

<style lang="scss" scoped>
.scroll-top-container {
    @apply h-10 w-10 fixed right-8 bottom-8 md:right-12 md:bottom-12 cursor-pointer;

    transition: all 0.3s linear;
    z-index: 45;
    opacity: 0;
    visibility: hidden;

    &.showing {
        opacity: 1;
        visibility: visible;
    }

    .scroll-top-icon {
        @apply w-full h-full;
        transition: all 0.3s linear;

        i {
            @apply absolute top-1/2 right-1/2;
            transition: all 0.3s linear;
            font-size: 1.3rem;
            transform: translate(50%, -50%);
        }

        @screen md {
            &:hover {
                i {
                    transform: translate(50%, -250%);
                }
            }
        }
    }

    &.scrolling {
        .scroll-top-icon {
            i {
                transform: translate(50%, -250%);
            }
        }
    }
}
</style>
